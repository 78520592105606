import {entries} from "lodash";
import {downloadFile} from "./inkonfigHelpers";
import Swal from "sweetalert2/dist/sweetalert2.js";
const packageJson = require("../../package.json");

function generateScript(e) {
  e.preventDefault();
  const formData = Object.fromEntries(new FormData(e.target), entries());

  console.log(formData);

  let scriptContent = ` 
@echo off
title Inkonfig Initializer
setlocal EnableDelayedExpansion
NET FILE 1>NUL 2>NUL
if '%errorlevel%' == '0' ( goto continue ) else ( goto getPrivileges ) 
:getPrivileges
if '%1'=='ELEV' ( goto continue )
set "batchPath=%~f0"
set "batchArgs=ELEV"
set "script=%0"
set script=%script:"=%
IF '%0'=='!script!' ( GOTO PathQuotesDone )
    set "batchPath=""%batchPath%"""
:PathQuotesDone
:ArgLoop
IF '%1'=='' ( GOTO EndArgLoop ) else ( GOTO AddArg )
    :AddArg
    set "arg=%1"
    set arg=%arg:"=%
    IF '%1'=='!arg!' ( GOTO NoQuotes )
        set "batchArgs=%batchArgs% "%1""
        GOTO QuotesDone
        :NoQuotes
        set "batchArgs=%batchArgs% %1"
    :QuotesDone
    shift
    GOTO ArgLoop
:EndArgLoop
ECHO [Inkonfig Initializer] Administrative permissions required. Requesting...
ECHO Set UAC = CreateObject^("Shell.Application"^) > "%temp%\\OEgetPrivileges.vbs"
ECHO UAC.ShellExecute "cmd", "/c ""!batchPath! !batchArgs!""", "", "runas", 1 >> "%temp%\\OEgetPrivileges.vbs"
"%temp%\\OEgetPrivileges.vbs" 
exit /B
:continue
IF '%1'=='ELEV' ( shift /1 )
cd /d %~dp0
title Inkonfig Configuration Script (v.${packageJson.version})
echo.
echo  _____      _                __ _       
echo ^|_   _^|    ^| ^|              / _(_)      
echo   ^| ^| _ __ ^| ^| _____  _ __ ^| ^|_ _  __ _ 
echo   ^| ^|^| '_ \\^| ^|/ / _ \\^| '_ \\^|  _^| ^|/ _\` ^|
echo  _^| ^|^| ^| ^| ^|   ^< (_) ^| ^| ^| ^| ^| ^| ^| (_^| ^|
echo  \\___/_^| ^|_^|_^|\\_\\___/^|_^| ^|_^|_^| ^|_^|\\__, ^|
echo                                    __/ ^|
echo              Configuration Script ^|___/  
echo.
echo Generated by Inkonfig Wizard version ${packageJson.version}
echo https://inkonfig.hexandcube.eu.org
echo.
set /p startscript=Do you want to start the script? [y/N]:
if not '%startscript%'=='' SET choice=%choice:~0,1%
if /i '%startscript%'=='Y' goto start
exit
:start
echo.
title Inkonfig Configuration Script (v.${packageJson.version}) [Running...]
echo [Inkonfig] Configuring your settings, please wait...`;

  // Personalization
  
  if (formData.pcName !== "") {
    scriptContent += `\nWMIC computersystem where caption="%computername%" rename "${formData.pcName}"`;
  }

  if (formData.showFileExt === "true") {
    scriptContent += `\nREG add "HKCU\\Software\\Microsoft\\Windows\\CurrentVersion\\Explorer\\Advanced" /v HideFileExt /t REG_DWORD /d 0 /f`;
  } else if (formData.showFileExt === "false") {
    scriptContent += `\nREG add "HKCU\\Software\\Microsoft\\Windows\\CurrentVersion\\Explorer\\Advanced" /v HideFileExt /t REG_DWORD /d 1 /f`;
  }

  if (formData.launchExplorerTo === "thispc") {
    scriptContent += `\nREG add "HKEY_CURRENT_USER\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\Explorer\\Advanced" /v "LaunchTo" /t REG_DWORD /d "1" /f`;
  } else if (formData.launchExplorerTo === "quickaccess") {
    scriptContent += `\nREG add "HKEY_CURRENT_USER\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\Explorer\\Advanced" /v "LaunchTo" /t REG_DWORD /d "2" /f`;
  }

  if (formData.showSecondsInTaskbarClock === "true") {
    scriptContent += `\nREG add "HKEY_CURRENT_USER\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\Explorer\\Advanced" /v "ShowSecondsInSystemClock" /t REG_DWORD /d "1" /f`;
  } else if (formData.showSecondsInTaskbarClock === "false") {
    scriptContent += `\nREG add "HKEY_CURRENT_USER\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\Explorer\\Advanced" /v "ShowSecondsInSystemClock" /t REG_DWORD /d "0" /f`;
  }

  if (formData.enableCtrlAltDelToLogin === "true") {
    scriptContent += `\nREG add "HKEY_LOCAL_MACHINE\\SOFTWARE\\Microsoft\\Windows NT\\CurrentVersion\\Winlogon" /v "DisableCAD" /t REG_DWORD /d "0" /f`;
  } else if (formData.enableCtrlAltDelToLogin === "false") {
    scriptContent += `\nREG add "HKEY_LOCAL_MACHINE\\SOFTWARE\\Microsoft\\Windows NT\\CurrentVersion\\Winlogon" /v "DisableCAD " /t REG_DWORD /d "1" /f`;
  }

  if (formData.enableVerboseLogon === "true") {
    scriptContent += `\nREG add "HKEY_LOCAL_MACHINE\\Software\\Microsoft\\Windows\\CurrentVersion\\Policies\\System" /v "VerboseStatus" /t REG_DWORD /d "1" /f`;
  } else if (formData.enableVerboseLogon === "false") {
    scriptContent += `\nREG add "HKEY_LOCAL_MACHINE\\Software\\Microsoft\\Windows\\CurrentVersion\\Policies\\System" /v "VerboseStatus" /t REG_DWORD /d "0" /f`;
  }

  if (formData.showWindowsVersionOnDesktop === "true") {
    scriptContent += `\nREG add "HKCU\\Control Panel\\Desktop" /V PaintDesktopVersion /T REG_DWORD /D 1 /F`
  } else if (formData.showWindowsVersionOnDesktop === "false") {
    scriptContent += `\nREG DELETE "HKCU\\Control Panel\\Desktop" /V PaintDesktopVersion /F`
  }

  if (formData.shellDocumentsLocation) {
    scriptContent += `\nREG add "HKCU\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\Explorer\\User Shell Folders" /v "{F42EE2D3-909F-4907-8871-4C22FC0BF756}" /t REG_EXPAND_SZ /d "${formData.shellDocumentsLocation}" /f`;
    scriptContent += `\nREG add "HKCU\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\Explorer\\User Shell Folders" /v "Personal" /t REG_EXPAND_SZ /d "${formData.shellDocumentsLocation}" /f`;
  }

  if (formData.shellPicturesLocation) {
    scriptContent += `\nREG add "HKCU\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\Explorer\\User Shell Folders" /v "{0DDD015D-B06C-45D5-8C4C-F59713854639}" /t REG_EXPAND_SZ /d "${formData.shellPicturesLocation}" /f`;
    scriptContent += `\nREG add "HKCU\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\Explorer\\User Shell Folders" /v "My Pictures" /t REG_EXPAND_SZ /d "${formData.shellPicturesLocation}" /f`;
  }

  if (formData.shellVideosLocation) {
    scriptContent += `\nREG add "HKCU\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\Explorer\\User Shell Folders" /v "{35286A68-3C57-41A1-BBB1-0EAE73D76C95}" /t REG_EXPAND_SZ /d "${formData.shellVideosLocation}" /f`;
    scriptContent += `\nREG add "HKCU\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\Explorer\\User Shell Folders" /v "My Video" /t REG_EXPAND_SZ /d "${formData.shellVideosLocation}" /f`;
  }

  if (formData.shellMusicLocation) {
    scriptContent += `\nREG add "HKCU\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\Explorer\\User Shell Folders" /v "{A0C69A99-21C8-4671-8703-7934162FCF1D}" /t REG_EXPAND_SZ /d "${formData.shellMusicLocation}" /f`;
    scriptContent += `\nREG add "HKCU\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\Explorer\\User Shell Folders" /v "My Music" /t REG_EXPAND_SZ /d "${formData.shellMusicLocation}" /f`;
  }

  if (formData.shellDesktopLocation) {
    scriptContent += `\nREG add "HKCU\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\Explorer\\User Shell Folders" /v "Desktop" /t REG_EXPAND_SZ /d "${formData.shellDesktopLocation}" /f`;
  }

  if (formData.shellDownloadsLocation) {
    scriptContent += `\nREG add "HKCU\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\Explorer\\User Shell Folders" /v "{7D83EE9B-2244-4E70-B1F5-5393042AF1E4}" /t REG_EXPAND_SZ /d "${formData.shellDownloadsLocation}" /f`;
  } 

  if (formData.enableClassicContextMenus === "true") {
    scriptContent += `\nREG add "HKCU\\Software\\Classes\\CLSID\\{86ca1aa0-34aa-4e8b-a509-50c905bae2a2}\\InprocServer32" /f /ve"`;
  } else if (formData.enableClassicContextMenus === "false") {
    scriptContent += `\nREG delete "HKCU\\Software\\Classes\\CLSID\\{86ca1aa0-34aa-4e8b-a509-50c905bae2a2}" /f"`;
  }

  // Power Plan

  if (formData.powerPlan === "balanced") {
    scriptContent += `\npowercfg -setactive 381b4222-f694-41f0-9685-ff5bb260df2e`;
  } else if (formData.powerPlan === "highperformance") {
    scriptContent += `\npowercfg -setactive 8c5e7fda-e8bf-4a96-9a85-a6e23a8c635c`;
  } else if (formData.powerPlan === "powersaver") {
    scriptContent += `\npowercfg -setactive a1841308-3541-4fab-bc81-f71556f20b4a`;
  }

  if (formData.enableHibernation === "true") {
    scriptContent += `\npowercfg -H ON`;
  } else if (formData.enableHibernation === "false") {
    scriptContent += `\npowercfg -H OFF`;
  }

  if (formData.acLidCloseAction !== "default") {
    scriptContent += `\npowercfg -setacvalueindex SCHEME_CURRENT 4f971e89-eebd-4455-a8de-9e59040e7347 5ca83367-6e45-459f-a27b-476b1d01c936 ${formData.acLidCloseAction}`;
  }

  if (formData.battLidCloseAction !== "default") {
    scriptContent += `\npowercfg -setdcvalueindex SCHEME_CURRENT 4f971e89-eebd-4455-a8de-9e59040e7347 5ca83367-6e45-459f-a27b-476b1d01c936 ${formData.battLidCloseAction}`;
  }

  if (formData.acPowerButtonAction !== "default") {
    scriptContent += `\npowercfg -setacvalueindex SCHEME_CURRENT 4f971e89-eebd-4455-a8de-9e59040e7347 7648efa3-dd9c-4e3e-b566-50f929386280 ${formData.acPowerButtonAction}`;
  }

  if (formData.battPowerButtonAction !== "default") {
    scriptContent += `\npowercfg -setdcvalueindex SCHEME_CURRENT 4f971e89-eebd-4455-a8de-9e59040e7347 7648efa3-dd9c-4e3e-b566-50f929386280 ${formData.battPowerButtonAction}`;
  }

  if (formData.acSleepButtonAction !== "default") {
    scriptContent += `\npowercfg -setacvalueindex SCHEME_CURRENT 4f971e89-eebd-4455-a8de-9e59040e7347 96996bc0-ad50-47ec-923b-6f41874dd9eb ${formData.acSleepButtonAction}`;
  }

  if (formData.battSleepButtonAction !== "default") {
    scriptContent += `\npowercfg -setdcvalueindex SCHEME_CURRENT 4f971e89-eebd-4455-a8de-9e59040e7347 96996bc0-ad50-47ec-923b-6f41874dd9eb ${formData.battSleepButtonAction}`;
  }
    
  // Software

  if (formData.installSoftwareChoco) {
    if (!formData.dontInstallChocolatey) {
      scriptContent += `\n@"%SystemRoot%\\System32\\WindowsPowerShell\\v1.0\\powershell.exe" -NoProfile -InputFormat None -ExecutionPolicy Bypass -Command "iex ((New-Object System.Net.WebClient).DownloadString('https://chocolatey.org/install.ps1'))" && SET "PATH=%PATH%;%ALLUSERSPROFILE%\\chocolatey\\bin"`;
    }

    if (formData.softwareToInstall) {
      scriptContent += `\nchoco install ${formData.softwareToInstall} --y --acceptlicense --ignoredetectedreboot`;
    }
  }

  if (formData.disableAds === "true") {
    scriptContent += `\nREG add "HKCU\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\Explorer\\Advanced" /v "ShowSyncProviderNotifications" /t REG_DWORD /d "0" /f`;
    scriptContent += `\nREG add "HKCU\\Software\\Microsoft\\Windows\\CurrentVersion\\ContentDeliveryManager" /v "SilentInstalledAppsEnabled" /t REG_DWORD /d "0" /f`;
    scriptContent += `\nREG add "HKCU\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\ContentDeliveryManager" /v "SystemPaneSuggestionsEnabled" /t REG_DWORD /d "0" /f`;
    scriptContent += `\nREG add "HKCU\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\ContentDeliveryManager" /v "SoftLandingEnabled" /t REG_DWORD /d "0" /f`;
    scriptContent += `\nREG add "HKCU\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\ContentDeliveryManager" /v "RotatingLockScreenEnabled" /t REG_DWORD /d "0" /f`;
    scriptContent += `\nREG add "HKCU\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\ContentDeliveryManager" /v "RotatingLockScreenOverlayEnabled" /t REG_DWORD /d "0" /f`;
    scriptContent += `\nREG add "HKCU\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\ContentDeliveryManager" /v "SubscribedContent-310093Enabled" /t REG_DWORD /d "0" /f`;
  } else if (formData.disableAds === "false") {
    scriptContent += `\nREG add "HKCU\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\Explorer\\Advanced" /v "ShowSyncProviderNotifications" /t REG_DWORD /d "1" /f`;
    scriptContent += `\nREG add "HKCU\\Software\\Microsoft\\Windows\\CurrentVersion\\ContentDeliveryManager" /v "SilentInstalledAppsEnabled" /t REG_DWORD /d "1" /f`;
    scriptContent += `\nREG add "HKCU\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\ContentDeliveryManager" /v "SystemPaneSuggestionsEnabled" /t REG_DWORD /d "1" /f`;
    scriptContent += `\nREG add "HKCU\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\ContentDeliveryManager" /v "SoftLandingEnabled" /t REG_DWORD /d "1" /f`;
    scriptContent += `\nREG add "HKCU\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\ContentDeliveryManager" /v "RotatingLockScreenEnabled" /t REG_DWORD /d "1" /f`;
    scriptContent += `\nREG add "HKCU\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\ContentDeliveryManager" /v "RotatingLockScreenOverlayEnabled" /t REG_DWORD /d "1" /f`;
    scriptContent += `\nREG add "HKCU\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\ContentDeliveryManager" /v "SubscribedContent-310093Enabled" /t REG_DWORD /d "1" /f`;
  }

  if (formData.installWsl) {
    scriptContent += `\nwsl --install`;
    if (formData.wslDistro) {
      scriptContent += ` -d ${formData.wslDistro}`;
    }

  }

  // Advanced Configuration

  if (formData.tempPath) {
    scriptContent += `\nIF NOT EXIST "${formData.tempPath}" ( mkdir ${formData.tempPath} )`;
    scriptContent += `\nsetx /m TEMP ${formData.tempPath}`;
    scriptContent += `\nsetx /m TMP ${formData.tempPath}`;
    scriptContent += `\nsetx TEMP ${formData.tempPath}`;
    scriptContent += `\nsetx TMP ${formData.tempPath}`;
  }

  if (formData.enableShutdownEventTracker === "true") {
    scriptContent += `\nREG add "HKEY_LOCAL_MACHINE\\SOFTWARE\\Policies\\Microsoft\\Windows NT\\Reliability" /v "ShutdownReasonOn" /t REG_DWORD /d "1" /f`;
    scriptContent += `\nREG add "HKEY_LOCAL_MACHINE\\SOFTWARE\\Policies\\Microsoft\\Windows NT\\Reliability" /v "ShutdownReasonUI" /t REG_DWORD /d "1" /f`;
  } else if (formData.enableShutdownEventTracker === "false") {
    scriptContent += `\nREG add "HKEY_LOCAL_MACHINE\\SOFTWARE\\Policies\\Microsoft\\Windows NT\\Reliability" /v "ShutdownReasonOn" /t REG_DWORD /d "0" /f`;
    scriptContent += `\nREG add "HKEY_LOCAL_MACHINE\\SOFTWARE\\Policies\\Microsoft\\Windows NT\\Reliability" /v "ShutdownReasonUI" /t REG_DWORD /d "0" /f`;
  }

  scriptContent += `\ntitle Inkonfig Configuration Script (v.${packageJson.version}) [Finished]`;

  if (formData.restartComputer) {
    scriptContent += "\necho.\necho [Inkonfig] Configuration completed, press any key to restart.\npause >nul";
    scriptContent += `\nshutdown /r /t 5 /d P:2:4 /c "[Inkonfig] Restarting after configuring Windows"`
  } else {
    scriptContent += "\necho.\necho [Inkonfig] Configuration completed, press any key to exit.\npause >nul";
    scriptContent += "exit";
  }

  var scriptFileName;

  if (formData.scriptName) {
    scriptFileName = `${formData.scriptName}.${formData.scriptExtension}`
  } else {
    scriptFileName = "InkonfigScript." + formData.scriptExtension;
  }

  downloadFile(scriptContent, scriptFileName, "application/bat");

  Swal.fire({icon: "success", title: "Script generated!", timer: 4000, timerProgressBar: true, showConfirmButton: false, toast: true, position: "top-right"});
}

export default generateScript;